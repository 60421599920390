var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-8 pb-12"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('router-link',{attrs:{"to":{ name: 'AssociationAttachmentsTypesIndex'}}},[_c('cv-button',{attrs:{"kind":"secondary"}},[_vm._v(" Voltar para Pontuação por Documento ")])],1)],1),_c('div',{staticClass:"flex justify-center my-4"},[_c('ValidationObserver',{staticClass:"w-full px-3 lg:w-1/2 xl:w-1/3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl"},[_vm._v(" Editar Pontuação do Documento ")]),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome","invalid-message":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"description","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-area',{attrs:{"label":"Descrição","invalid-message":errors[0],"rows":5},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"point","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-number-input',{directives:[{name:"mask",rawName:"v-mask",value:(['##.#', '#.#']),expression:"['##.#', '#.#']"}],attrs:{"label":"Pontuação","invalid-message":errors[0],"min":0,"max":99.9,"step":0.1},model:{value:(_vm.form.point),callback:function ($$v) {_vm.$set(_vm.form, "point", $$v)},expression:"form.point"}})]}}],null,true)}),_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),_c('div',{staticClass:"mt-6"},[(_vm.done)?_c('cv-button',{on:{"click":function($event){_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_vm._v(" Salvar Alterações ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }