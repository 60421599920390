









































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { mask } from 'vue-the-mask'

import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class AssociationAttachmentsTypesEdit extends Mixins(FlashMessageMixin) {
  type = {}
  done = true
  form: Record<string, any>= {
    name: '',
    description: '',
    point: 0,
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  created () {
    this.validations()

    axios.get(`user/association/attachment/type/${this.$route.params.id}`)
      .then(response => {
        const { name, description, point } = response.data.data
        this.form = {
          name,
          description,
          point: +point,
          confirm: '',
          errors: {
            confirm: ''
          }
        }
      })
  }

  submit () {
    this.done = false
    axios.patch(`user/association/attachment/type/${this.$route.params.id}`, this.form)
      .then(() => {
        this.$router.push({ name: 'AssociationAttachmentsTypesIndex' }, () => {
          this.setFlashMessage({ message: 'Pontuação do Documento editada', isSuccess: true })
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })
  }
}
